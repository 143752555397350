sl-page-spinner {
  display: inline;

  ion-row {
    height: 100%;
  }

  ion-col {
    margin: auto;
    width: 100%;
    text-align: center;
  }
}

.ios sl-page-spinner ion-spinner {
  width: 32px;
  height: 32px;
}

.md sl-page-spinner ion-spinner {
  width: 48px;
  height: 48px;
}
