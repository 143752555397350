.show-badge .tab-button:nth-child(4) .tab-button-icon {
  position: relative;
  overflow: visible;

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    border: 2px solid #fff;
    border-radius: 50%;
    background-color: color($sl-colors, primary);
    width: 0.6rem;
    height: 0.6rem;
    content: '';
  }
}

.tabs-ios .tab-button-icon {
  &::after {
    margin: 0 auto;
    width: 3rem;
    height: 3rem;
  }
}

.platform-core {
  .tabs-md {
    .tab-button-text {
      font-size: 1.6rem;
    }
  }

  .show-badge .tab-button:nth-child(4) .tab-button-icon {
    &::before {
      margin-right: 0.4rem;
    }
  }
}
