@import '../../../node_modules/cropperjs/dist/cropper';

image-editor-page {
  ion-grid {
    height: 100%;
  }

  .cropper {
    width: 100%;
    height: 100%;

    img {
      max-width: 100%;
    }
  }
}
