@mixin sl-statusbar-padding($toolbar-height, $toolbar-padding, $content-padding, $cordova-statusbar-padding, $modal-max-width, $style-title: false) {

  ion-nav > .ion-page,
  ion-nav > .ion-page > ion-header,
  ion-tab > .ion-page,
  ion-tab > .ion-page > ion-header,
  ion-tabs > .ion-page.tab-subpage > ion-header,
  ion-menu > .menu-inner,
  ion-menu > .menu-inner > ion-header {

    sl-logo-navbar {
      @include toolbar-statusbar-padding($toolbar-height, $toolbar-padding, $content-padding, $cordova-statusbar-padding);

      // If we should style the title elements in the toolbar
      @if ($style-title) {
        @include toolbar-title-statusbar-padding($toolbar-height, $toolbar-padding, $content-padding, $cordova-statusbar-padding);
      }
    }
  }
}

.ios {
  @include sl-statusbar-padding($toolbar-ios-height, $toolbar-ios-padding, $content-ios-padding, $cordova-ios-statusbar-padding, $cordova-ios-statusbar-padding-modal-max-width, true);

  // If modal is floating
  @media only screen and (min-width: $modal-inset-min-width) and (min-height: $modal-inset-min-height-small) {
    ion-modal:not(.fullscreen) ion-nav > .ion-page {
      // Don't apply `safe-area-inset-top` to header toolbar
      .toolbar.statusbar-padding:first-child,
      ion-header > .toolbar.statusbar-padding:first-child {
        padding-top: 0;
        min-height: $toolbar-ios-height;

        ion-title {
          padding-top: 0;
          height: $toolbar-ios-height;
          min-height: $toolbar-ios-height;
        }
      }

      // Don't apply `safe-area-inset-bottom` to footer toolbar
      ion-footer .toolbar:last-child {
        padding-bottom: 0;
        min-height: $toolbar-ios-height;
      }
    }
  }

  // Don't apply `safe-area-inset-bottom` to footer toolbar if tabs are below and shown
  // https://github.com/ionic-team/ionic/pull/13622
  // https://github.com/ionic-team/ionic/pull/13674
  .tabs:not([tabsPlacement='top']):not(.tabbar-hidden) ion-footer .toolbar:last-child {
    padding-bottom: $toolbar-ios-padding;
    min-height: $toolbar-ios-height;
  }
}
