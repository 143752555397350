// http://ionicframework.com/docs/v2/theming/


// App Global Sass
// --------------------------------------------------
// Put style rules here that you want to apply globally. These
// styles are for the entire app and not just one component.
// Additionally, this file can be also used as an entry point
// to import other Sass files to be included in the output CSS.
//
// Shared Sass variables, which can be used to adjust Ionic's
// default Sass variables, belong in "theme/variables.scss".
//
// To declare rules for a specific mode, create a child rule
// for the .md, .ios, or .wp mode classes. The mode class is
// automatically applied to the <body> element in the app.

@mixin text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin text-fade {
  position: relative;
  overflow: hidden;
  text-overflow: clip;
  white-space: nowrap;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    width: 2.4rem;
    height: 100%;
    content: '';
  }
}

@mixin text-fade-hover {
  &::after {
    background-image: linear-gradient(to right, rgba(#f2f2f2, 0), rgba(#f2f2f2, 1));
  }
}

[primary-text] {
  color: color($sl-text, primary);
}

[secondary-text] {
  color: color($sl-text, secondary);
}

[disabled-text] {
  color: color($sl-text, disabled);
}

[hint-text] {
  color: color($sl-text, hint);
}

[helper-text] {
  margin: 8px 0;
  color: color($sl-text, secondary);
  font-size: 1.2rem;
}

[text-ellipsis] {
  @include text-ellipsis;
}

[tappable] {
  cursor: pointer;
}

ion-header .logo {
  flex-shrink: 0;
  padding: 0 16px 0 8px;

  img {
    display: block;
    height: 2rem;
  }
}

.content-ios,
.content-md {
  background-color: color($sl-colors, background);
}

.bar-button {
  padding: 0 10px;
  font-weight: 600;

  &.bar-button-ios-primary,
  &.bar-button-md-primary {
    background-color: color($sl-colors, primary, base);
    color: color($sl-colors, primary, contrast);

    &:hover:not(.disable-hover) {
      color: color($sl-colors, primary, contrast);
    }
  }

  &.bar-button-ios-secondary,
  &.bar-button-md-secondary {
    background-color: color($sl-colors, secondary, base);
    color: color($sl-colors, secondary, contrast);

    &:hover:not(.disable-hover) {
      color: color($sl-colors, secondary, contrast);
    }
  }
}

.bar-button[icon-only] ion-icon,
.bar-button[icon-only] sl-user-avatar {
  min-width: 28px;
}

[class*='ion-ios-sl-'],
[class*='ion-md-sl-'] {
  &::after {
    display: block;
    width: 2.4rem;
    height: 2.4rem;
  }
}

.ion-ios-sl-home,
.ion-md-sl-home {
  &::after {
    content: url('../assets/images/sl-home.svg');
  }
}

.ion-ios-sl-home-outline {
  &::after {
    content: url('../assets/images/sl-home-outline.svg');
  }
}

.ion-ios-sl-explore,
.ion-md-sl-explore {
  &::after {
    content: url('../assets/images/sl-explore.svg');
  }
}

.ion-ios-sl-explore-outline {
  &::after {
    content: url('../assets/images/sl-explore-outline.svg');
  }
}

.ion-ios-sl-lists,
.ion-md-sl-lists {
  &::after {
    content: url('../assets/images/sl-lists.svg');
  }
}

.ion-ios-sl-lists-outline {
  &::after {
    content: url('../assets/images/sl-lists-outline.svg');
  }
}

.ion-ios-sl-notifications,
.ion-md-sl-notifications {
  &::after {
    content: url('../assets/images/sl-notifications.svg');
  }
}

.ion-ios-sl-notifications-outline {
  &::after {
    content: url('../assets/images/sl-notifications-outline.svg');
  }
}

.ion-ios-sl-profile,
.ion-md-sl-profile {
  &::after {
    content: url('../assets/images/sl-profile.svg');
  }
}

.ion-ios-sl-profile-outline {
  &::after {
    content: url('../assets/images/sl-profile-outline.svg');
  }
}

.ion-ios-sl-like,
.ion-md-sl-like {
  &::after {
    content: url('../assets/images/sl-like.svg');
  }
}

.ion-ios-sl-like-outline,
.ion-md-sl-like-outline {
  &::after {
    content: url('../assets/images/sl-like-outline.svg');
  }
}

.ion-ios-sl-comment-outline,
.ion-md-sl-comment-outline {
  &::after {
    content: url('../assets/images/sl-comment-outline.svg');
  }
}

.ion-ios-sl-send-outline,
.ion-md-sl-send-outline {
  &::after {
    content: url('../assets/images/sl-send-outline.svg');
  }
}

.ion-ios-sl-share-outline {
  &::after {
    content: url('../assets/images/sl-share-ios-outline.svg');
  }
}

.platform-android .ion-md-sl-share-outline {
  &::after {
    content: url('../assets/images/sl-share-android-outline.svg');
  }
}

.platform-core .ion-md-sl-share-outline {
  &::after {
    content: url('../assets/images/sl-share-web-outline.svg');
  }
}

.ion-ios-sl-save,
.ion-md-sl-save {
  &::after {
    content: url('../assets/images/sl-save.svg');
  }
}

.ion-ios-sl-save-outline,
.ion-md-sl-save-outline {
  &::after {
    content: url('../assets/images/sl-save-outline.svg');
  }
}

.ion-ios-sl-shop,
.ion-md-sl-shop {
  &::after {
    width: 1.6rem;
    height: 1.6rem;
    content: url('../assets/images/sl-shop.svg');
  }
}

.tabs-md .tab-button[aria-selected='false'] {
  .ion-md-sl-home {
    &::after {
      content: url('../assets/images/sl-home-outline.svg');
    }
  }

  .ion-md-sl-explore {
    &::after {
      content: url('../assets/images/sl-explore-outline.svg');
    }
  }

  .ion-md-sl-lists {
    &::after {
      content: url('../assets/images/sl-lists-outline.svg');
    }
  }

  .ion-md-sl-notifications {
    &::after {
      content: url('../assets/images/sl-notifications-outline.svg');
    }
  }

  .ion-md-sl-profile {
    &::after {
      content: url('../assets/images/sl-profile-outline.svg');
    }
  }
}

ion-item-divider,
ion-list-header {
  font-weight: bold;

  ion-label {
    color: color($sl-text, primary);
    font-weight: bold;
  }
}

.item-ios,
.item-md {
  background-color: transparent;
}

ion-list .item > ion-icon,
ion-item-group .item > ion-icon {
  color: color($sl-text, icon);
}

.item.item-input .item-inner {
  border-bottom-color: color($sl-colors, input-line);
}

.item-input .text-input {
  margin-left: 0;
}

ion-textarea textarea {
  resize: none;
}

.alert-button-group .secondary {
  color: color($sl-text, secondary);
}

ion-popover .popover-content ion-list {
  margin-bottom: 0;
}

// Fixes refresher not being shown
ion-content.has-refresher > .scroll-content {
  background-color: transparent;
}

// Fix toast button having same color as background
.toast-button.button-clear {
  color: #fff;
}

.sl-icon {
  display: block;
  width: auto;
  height: 1em;
}

[icon-left] .sl-icon {
  padding-right: 0.3em;
  font-size: 1.4em;
}

[icon-only] .sl-icon {
  padding: 0 0.5em;
  font-size: 1.8em;
}

.md-icon {
  display: block;
  opacity: 0.38;
  width: auto;
  height: 1em;
}

[icon-left] .md-icon {
  padding-right: 0.3em;
  font-size: 1.4em;
}

[icon-only] .md-icon {
  font-size: 1.8em;
}

// Logged Out States
.logged-out {
  justify-content: center;
  min-height: 100%;

  ion-row {
    flex-grow: 1;
    align-content: center;
  }

  .container {
    margin: 8px auto 16px;
    max-width: 360px;
    text-align: center;
  }

  img {
    border-radius: 8px;
    box-shadow: $sl-card-shadow;
    width: 100%;
    max-width: 500px;
  }

  h1 {
    margin: 8px 0;
  }

  h6 {
    margin: 8px 0 24px;
    font-weight: 600;
  }

  p {
    line-height: 1.5;
    color: color($sl-text, secondary);
    font-size: 16px;
  }
}

// Fullscreen Modals with `{ cssClass: 'fullscreen' }`
ion-modal.fullscreen {
  .modal-wrapper {
    top: initial;
    left: initial;
    border-radius: initial;
    width: 100%;
    height: 100%;
    overflow: initial;
  }
}

// iOS

.ios {
  .bar-button-ios {
    font-weight: 500;
  }

  .bar-button-ios[icon-only] {
    margin: 0 0.2rem;
    min-width: 28px;
  }

  .button-ios {
    font-weight: 600;
  }

  // Fix to remove 20px of extra padding-top from segment in the header
  ion-nav > .ion-page > ion-header > .toolbar.statusbar-padding:first-child ion-segment {
    padding-top: 0;
    height: 44px;
    min-height: 44px;
  }

  .segment-md .segment-button {
    max-width: none;
    text-transform: none;
  }

  // iOS 11 Searchbar style
  .searchbar-ios {
    .searchbar-input {
      border-radius: 10px;
      font-size: 1.6rem;
    }

    .searchbar-search-icon {
      top: 12px;
      left: 10px;
    }
  }
}


// Material Design

.toolbar-title-md {
  font-weight: bold;
}

.segment-md .segment-button {
  color: color($sl-text, secondary);
  font-weight: 600;

  &.segment-activated {
    color: color($sl-colors, primary);
  }
}

// Mobile + Phablet

@media (max-width: map-get($grid-breakpoints, sm)) {
  .platform-core .tab-button.has-title {
    .tab-button-icon {
      padding: 0;
      text-align: center;
    }

    .tab-button-text {
      display: none;
    }
  }
}

// Desktop

ion-header .toolbar {
  margin: auto;
}

@media (min-width: map-get($grid-breakpoints, lg)) {
  ion-navbar.toolbar,
  sl-logo-navbar .toolbar,
  ion-header .toolbar[fixed],
  ion-tabs .tabbar {
    margin: 0;
    padding-right: calc((100% - #{map-get($grid-max-widths, lg)}) / 2);
    padding-left: calc((100% - #{map-get($grid-max-widths, lg)}) / 2);
  }
}
