edit-list-page {
  .media-container {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    max-height: 360px;
    overflow: hidden;
  }

  .media {
    display: table;
    position: relative;
    background-position: 50% 50%;
    background-size: cover;
    padding-bottom: 56.25%;
    width: 100%;
    height: 0;

    button {
      position: absolute;
      margin: 0;
      border-radius: 0;
      width: 100%;
      height: 100%;
    }
  }

  .item-divider {
    border-bottom: 0;
  }

  .error {
    color: color($sl-colors, primary);
  }

  .topics ion-chip {
    margin-right: 8px;
  }

  .item ion-thumbnail {
    position: relative;
    border-radius: 6px;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    min-width: 3.2rem;
    min-height: 3.2rem;

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: 6px;
      background-color: color($sl-colors, hover);
      content: '';
    }
  }

  ion-footer {
    right: 0;
    margin: 0 auto;
    max-width: map-get($grid-max-widths, lg);
  }

  @media (max-width: map-get($grid-breakpoints, sm)) {
    .grid > .row > .col.section {
      padding-bottom: 16px;
    }
  }

  @media (min-width: map-get($grid-breakpoints, md)) {
    .grid > .row > .col.section {
      padding: 16px;
    }
  }

  @media (min-width: map-get($grid-breakpoints, lg)) {
    .grid[fixed] {
      padding-top: 16px;
    }

    .media-container {
      border-radius: 6px;
    }
  }
}
