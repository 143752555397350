tutorial-page {
  .browser-top {
    .toolbar-background {
      border-bottom-width: 0.55px;
      background-color: color($sl-colors, light);
    }
  }

  .urlbar-container-ios {
    display: flex;
    position: relative;
    align-items: center;
    padding: 0 8px;
    width: 100%;
  }

  .urlbar-container-md {
    display: flex;
    position: relative;
    align-items: center;
    padding: 5px 10px 5px 5px;
    width: 100%;
  }

  .urlbar-ios {
    display: block;
    position: relative;
    flex-shrink: 1;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.08);
    width: 100%;
    height: 3rem;
    text-align: center;
    line-height: 3rem;
    font-size: 1.6rem;

    ion-icon {
      margin-right: 5px;
      font-size: 1.6rem;
    }
  }

  .urlbar-md {
    display: block;
    position: relative;
    flex-shrink: 1;
    border-radius: 2px;
    box-shadow: $sl-button-shadow;
    background-color: #fff;
    padding-left: 16px;
    width: 100%;
    height: 38px;
    line-height: 38px;
    font-size: 1.6rem;

    .secure {
      color: #0b8043;
    }

    .hint {
      color: #999;
    }

    ion-icon {
      margin-top: -1px;
      margin-right: 8px;
      color: #0b8043;
      font-size: 1.8rem;
    }
  }

  .tabs {
    position: relative;

    &::after {
      display: block;
      position: absolute;
      top: 1px;
      right: 0;
      bottom: 0;
      left: 0;
      line-height: 1;
      font-family: $font-family-base;
      font-size: 1.2rem;
      content: '1';
    }
  }

  .bar-button-clear-md-secondary-alt.highlight {
    background-color: color($sl-colors, primary, base);
    color: color($sl-colors, primary, contrast);
  }

  ion-footer {
    .browser-bottom {
      padding: 0;

      .toolbar-background {
        background-color: color($sl-colors, light);
      }

      ion-col {
        padding: 0;
      }

      button ion-icon {
        color: #cacaca;

        &[name='share'] {
          color: #007aff;
        }
      }
    }
  }

  .info {
    padding: 16px;
    color: #fff;

    h2 {
      margin-top: 0;
      font-size: 2rem;
      font-weight: bold;
    }

    p {
      margin-bottom: 0;
      font-size: 1.6rem;
    }

    ion-row {
      margin-top: 10px;
    }

    ion-col {
      padding: 0;
    }

    button {
      box-shadow: none;
    }
  }

  .tutorial {
    .bar {
      color: #fff;
      font-size: 1.6rem;

      .toolbar-background {
        border-top-width: 0;
        background-color: color($sl-text, secondary);
      }
    }

    .store-name {
      padding-left: 8px;
    }

    .brand {
      margin: 0;
      color: color($sl-text, primary);
    }

    .title {
      margin-top: 0;
      color: color($sl-text, secondary);
      font-weight: bold;
    }

    .pricing {
      margin-bottom: 0;
      color: color($sl-text, secondary);
      font-size: 1.5rem;
    }

    .price {
      color: color($sl-text, primary);
      font-size: 1.8rem;
    }

    .shipping {
      margin-top: 0;
      color: color($sl-text, secondary);
      font-size: 1.5rem;
    }

    .availability {
      color: color($sl-text, secondary);
      font-size: 1.8rem;
    }

    .add-to-cart {
      border: 1px solid;
      border-color: color($sl-colors, hint) color($sl-colors, icon) color($sl-colors, secondary-alt);
      box-shadow: none;
      background-color: color($sl-colors, divider);
      background-image: linear-gradient(to bottom, color($sl-colors, divider), color($sl-colors, hint));
      height: 4.5rem;
      text-transform: none;
      color: color($sl-text, primary);
      font-size: 1.5rem;

      &:hover {
        background-color: color($sl-colors, divider);
      }
    }
  }

  .ion-ios-share,
  .ion-md-more {
    font-family: 'Ionicons';
  }
}
