sl-rating-card {
  display: block;

  .rating-card-container {
    margin: 0;
    padding: 5px 5px 13px;
    height: 100%;
  }

  .card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0;
    box-shadow: none;
    padding: 10px;
    width: 100%;
    height: 100%;

    ion-card-title {
      margin: 0;
      padding: 0;
      text-align: center;
      color: color($sl-text, primary);
      font-size: 1.6rem;
      font-weight: bold;
    }

    ion-card-content {
      margin: 0 16px;
    }

    .positive-button {
      font-weight: bold;
    }

  }

}
