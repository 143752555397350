select-product-page {
  .segment-md .segment-button {
    flex-basis: auto;
    width: auto;
    overflow: visible;
    text-overflow: clip;
    white-space: normal;
  }

  ion-segment {
    flex-grow: 0;
  }

  .tip {
    span {
      font-weight: 600;
    }
  }

  .browse-back {
    button {
      height: 3.6rem;
    }
  }

  ion-footer {
    .button {
      margin: 0;
      border-radius: 0;
      width: 100%;
      min-height: 5.6rem;
    }
  }
}
