share-page {

  ion-icon {
    &[name='mail'] {
      color: material-color('amber-400');
    }

    &[name='logo-facebook'] {
      color: #3b5999;
    }

    &[name='ios-text'] {
      color: #0084ff;
    }

    &[name='logo-whatsapp'] {
      color: #25d366;
    }

    &[name='logo-twitter'] {
      color: #55acee;
    }
  }

}
