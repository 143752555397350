sl-user-avatar {
  display: block;
  text-transform: none;
  font-family: $font-family-base;
  font-size: 1.4rem;

  .avatar {
    display: block;
    position: relative;
    border-radius: 50%;
    background-color: color($sl-colors, hover);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    padding: 0;
    width: 4rem;
    height: 4rem;
    text-align: center;
    line-height: 3.2rem;
    font-size: 2.4rem;
    font-weight: 600;

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border: 1px solid color($sl-colors, hover);
      border-radius: 50%;
      content: '';
    }
  }

  .username {
    padding: 0 0 0 8px;
    height: 2.4rem;
    line-height: 2.4rem;
    color: color($sl-text, primary);
    font-size: inherit;
  }

  .verified {
    padding: 0 0 0 4px;
    font-size: inherit;

    ion-icon {
      display: block;
      padding-right: 0.1rem; // Fix for cut out icon
      font-size: inherit;
    }

  }

  &[small] .avatar {
    width: 2.4rem;
    height: 2.4rem;
    line-height: 2rem;
    font-size: 1.6rem;
  }

  &[medium] .avatar {
    width: 5.6rem;
    height: 5.6rem;
    line-height: 4.8rem;
    font-size: 3.2rem;
  }

  &[large] .avatar {
    width: 8rem;
    height: 8rem;
    line-height: 6.4rem;
    font-size: 5.4rem;
  }

  &[extra-large] .avatar {
    width: 12rem;
    height: 12rem;
    line-height: 9.6rem;
    font-size: 8.1rem;
  }

}
