sl-user-card {
  display: flex;

  .user-card {
    display: flex;
    transition: transform 100ms linear;
    margin: 0;
    border-radius: 8px;
    box-shadow: $sl-card-shadow;
    padding: 0;
    width: 100%;

    &:hover,
    &:active {
      transform: scale3d(0.98, 0.98, 1);
    }

    &.no-shadow {
      box-shadow: none;

      &:hover,
      &:active {
        transform: none;
      }
    }
  }

  .item.item-block {
    display: flex;
    background: transparent;
    padding-left: 8px;

    .item-inner {
      padding-right: 0;
    }
  }

  .item [item-start] {
    margin-right: 16px;
  }

  .name {
    @include text-fade;
    color: color($sl-text, primary);
    font-weight: bold;
  }

  .verified {
    font-size: 1.6rem;
  }
}
