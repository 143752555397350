select-categories-page {
  .item ion-thumbnail {
    position: relative;
    border-radius: 6px;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: 6px;
      background-color: color($sl-colors, hover);
      content: '';
    }
  }

  .title {
    font-weight: 600;
  }

  .divider {
    border-bottom: $hairlines-width solid color($sl-colors, input-line);
    height: 1px;
  }

  .emoji[item-start] {
    margin-right: 16px;
    font-size: 2.4rem;
  }
}
