tutorial-finish-page {

  ion-grid {
    height: 100%;
  }

  ion-row {
    height: 100%;
    overflow-y: scroll;
  }

  ion-col {
    margin: auto;
    width: 100%;
    text-align: center;
  }

  h1 {
    font-size: 2rem;
    font-weight: bold;
  }

  p {
    font-size: 1.6rem;
  }

}
