sl-topic-chip {
  display: inline-block;
  margin: 4px 8px 4px 0;
  max-width: 100%;

  .chip {
    margin: 0;
    padding-right: 0;
    max-width: 100%;
    text-transform: none;

    ion-label {
      margin-right: 0;
      font-weight: 600;
    }

    .button {
      color: inherit;
    }

    ion-icon {
      min-width: 32px; // Fix for iOS 9
    }

  }

}
