sl-product-popover {
  display: block;
  position: absolute;
  z-index: 3;
  pointer-events: none;

  &.show {
    pointer-events: auto;

    .product-popover {
      transform: scale3d(1, 1, 1);
      transition: transform 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }
  }

  .product-popover {
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: scale3d(0, 0, 0);
    transition: transform 200ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
    will-change: transform;

    &.top {
      .box {
        order: 1;
      }

      .arrow {
        order: 0;
        transform: rotate(225deg);
        margin-bottom: -9px;
        box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.06);
      }
    }


    &.right {
      flex-direction: row;
      width: 170px;

      .arrow {
        transform: rotate(-45deg);
        margin-left: -9px;
        box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.12);
      }
    }

    &.bottom {
      .arrow {
        transform: rotate(45deg);
        margin-top: -9px;
        box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.14), 1px 1px 0 0 rgba(0, 0, 0, 0.12);
      }
    }

    &.left {
      flex-direction: row;
      width: 170px;

      .box {
        order: 1;
      }

      .arrow {
        order: 0;
        transform: rotate(135deg);
        margin-right: -9px;
        box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.12);
      }
    }
  }

  .box {
    border-radius: 4px;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    background-color: #fff;
    padding: 8px;
    width: 160px;

    p {
      margin: 0;
      line-height: 1.6rem;
      font-size: 1.4rem;
    }

    .title {
      max-height: 3.2rem;
      overflow: hidden;
      color: color($sl-text, primary);
      font-weight: 600;
    }

    .price {
      margin-top: 4px;
      color: color($sl-colors, primary);
    }
  }

  .arrow {
    margin: 0;
    border-radius: 0 0 4px;
    background: linear-gradient(-45deg, #fff 50%, transparent 50%);
    width: 16px;
    height: 16px;
  }

  @media (max-width: map-get($grid-breakpoints, sm)) {
    .product-popover {
      &.right,
      &.left {
        width: 130px;
      }
    }

    .box {
      width: 120px;

      p {
        line-height: 1.4rem;
        font-size: 1.2rem;
      }

      .title {
        max-height: 2.8rem;
      }
    }
  }
}
