notifications-page {
  .notifications-grid[fixed] {
    max-width: map-get($grid-breakpoints, md);
    height: 100%;
  }

  .day {
    margin-bottom: 24px;
  }

  .date {
    font-size: 2rem;
  }

  .item p {
    color: color($sl-text, primary);
    font-size: 1.4rem;

    &.title,
    span {
      font-weight: 600;
    }
  }

  .notification {
    flex-shrink: 0;
    border-radius: 6px;
    background-color: transparent;

    &:hover {
      background-color: color($sl-colors, hover);
    }

    h2 {
      @include text-ellipsis;
      font-weight: 600;
    }
  }

  .item {
    ion-thumbnail {
      position: relative;
      border-radius: 6px;
      background-color: #fff;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover;

      &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: 6px;
        background-color: color($sl-colors, hover);
        content: '';
      }
    }

    sl-user-avatar[item-start] {
      margin-right: 16px;
    }
  }

  .no-results {
    align-content: center;
    width: 100%;
    height: 100%;
    text-align: center;

    h2,
    p {
      width: 100%;
    }

    p {
      font-size: 1.6rem;
    }
  }
}
