.product-variants {

  ion-label {
    font-size: 1.4rem;
  }

  .prices {
    text-align: right;
  }

  .now {
    color: color($sl-text, primary);
  }

  .original {
    display: none;
    text-decoration: line-through;
    color: color($sl-text, hint);
    font-size: 1.4rem;
  }

  .sale {

    .now {
      color: color($sl-colors, primary);
    }

    .original {
      display: block;
    }

  }

}
