// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/v2/theming/
@import "ionic.globals";
@import "./material-colors";

// Shared Variables
// --------------------------------------------------
// To customize the look and feel of this app, you can override
// the Sass variables found in Ionic's source scss files.
// To view all the possible Ionic variables, see:
// http://ionicframework.com/docs/v2/theming/overriding-ionic-variables/

$font-family-base: -apple-system, BlinkMacSystemFont, "Roboto", "Noto Sans",
  "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif,
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$font-family-ios-base: $font-family-base;
$font-family-md-base: $font-family-base;
$font-family-wp-base: $font-family-base;

// Named Color Variables
// --------------------------------------------------
// Named colors makes it easy to reuse colors on various components.
// It's highly recommended to change the default colors
// to match your app's branding. Ionic uses a Sass map of
// colors so you can add, rename and remove colors as needed.
// The "primary" color is the only required color in the map.

$colors: (
  primary: (
    base: #d91515,
    contrast: #fff,
  ),
  primary-alt: (
    base: #fff,
    contrast: #d91515,
  ),
  secondary: (
    base: rgba(0, 0, 0, 0.05),
    contrast: #d91515,
  ),
  secondary-alt: (
    base: rgba(0, 0, 0, 0.54),
    contrast: #fff,
  ),
  icon: rgba(0, 0, 0, 0.38),
  hint: rgba(0, 0, 0, 0.26),
);

$sl-colors: map-merge(
  $colors,
  (
    primary-100: #ff7f7f,
    primary-200: #f66,
    primary-300: #ff4c4c,
    primary-400: #f33030,
    primary-600: #bf0000,
    primary-700: #a60000,
    primary-800: #8c0000,
    primary-900: #730000,
    light: #f5f5f5,
    green: #8bc34a,
    background: #fff,
    input-line: rgba(0, 0, 0, 0.26),
    divider: rgba(0, 0, 0, 0.1),
    hover: rgba(0, 0, 0, 0.05),
  )
);

$sl-text: (
  primary: rgba(0, 0, 0, 0.87),
  secondary: rgba(0, 0, 0, 0.54),
  disabled: rgba(0, 0, 0, 0.38),
  icon: rgba(0, 0, 0, 0.38),
  hint: rgba(0, 0, 0, 0.26),
);

$text-color: color($sl-text, primary);

$sl-button-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
  0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
$sl-card-shadow: 0 20px 80px 0 rgba(0, 0, 0, 0.1);

// Grid
// --------------------------------------------------

$grid-breakpoints: (
  xs: 0,
  // Phone (0px - 575px)
    sm: 576px,
  // Phablet (576px - 767px)
    md: 768px,
  // Tablet vertical (768px - 991px)
    lg: 992px // Tablet horizontal, Desktop (992px and above),,,,,
);

$grid-max-widths: (
  lg: 992px,
);

$grid-padding-width: 16px;
$grid-padding-widths: (
  xs: 16px,
  sm: 16px,
  md: 16px,
  lg: 24px,
);

// Font Weights
// --------------------------------------------------

$action-sheet-ios-button-cancel-font-weight: 600;
$action-sheet-ios-title-font-weight: bold;
$alert-ios-button-main-font-weight: 600;
$alert-ios-title-font-weight: bold;
$alert-md-button-font-weight: 600;
$alert-wp-button-font-weight: 600;
$alert-wp-title-font-weight: bold;
$badge-font-weight: 600;
$button-ios-strong-font-weight: bold;
$button-md-font-weight: 600;
$button-md-strong-font-weight: 600;
$button-wp-strong-font-weight: 600;
$card-ios-header-font-weight: bold;
$headings-font-weight: bold;
$list-ios-header-font-weight: bold;
$loading-ios-content-font-weight: 600;
$picker-button-ios-strong-font-weight: 600;
$searchbar-wp-input-font-weight: normal;
$segment-button-wp-font-weight: 600;
$tabs-md-tab-font-weight: normal;
$tabs-wp-tab-font-weight: normal;
$toolbar-button-ios-strong-font-weight: 600;
$toolbar-button-md-strong-font-weight: bold;
$toolbar-button-wp-strong-font-weight: bold;
$toolbar-ios-button-strong-font-weight: 600;
$toolbar-ios-title-font-weight: bold;
$toolbar-md-button-strong-font-weight: bold;
$toolbar-wp-button-strong-font-weight: bold;
$toolbar-wp-title-font-weight: bold;

// Headers
// --------------------------------------------------

$h1-font-size: 2.6rem;
$h2-font-size: 2.4rem;
$h3-font-size: 2.2rem;
$h4-font-size: 2rem;
$h5-font-size: 1.8rem;
$h6-font-size: 1.6rem;

// Toolbar
// --------------------------------------------------

$toolbar-background: rgba(255, 255, 255, 0.97);
$toolbar-ios-button-font-size: 1.6rem;
$toolbar-ios-title-text-color: color($sl-text, primary);
$toolbar-md-title-text-color: color($sl-text, primary);
$toolbar-wp-title-text-color: color($sl-text, primary);
$toolbar-ios-button-color: color($sl-text, icon);
$toolbar-md-button-color: color($sl-text, icon);
$toolbar-wp-button-color: color($sl-text, icon);

// Search Bar
// --------------------------------------------------

$searchbar-ios-input-background-color: color($sl-colors, hover);
$searchbar-md-input-background-color: color($sl-colors, hover);
$searchbar-wp-input-background-color: color($sl-colors, hover);
$searchbar-md-input-box-shadow: none;
$searchbar-ios-input-clear-icon-color: color($sl-text, secondary);
$searchbar-md-input-clear-icon-color: color($sl-text, secondary);
$searchbar-wp-input-clear-icon-color: color($sl-text, secondary);
$searchbar-ios-input-height: 3.6rem;
$searchbar-ios-input-placeholder-color: color($sl-text, icon);
$searchbar-md-input-placeholder-color: color($sl-text, icon);
$searchbar-wp-input-placeholder-color: color($sl-text, icon);
$searchbar-ios-input-search-icon-color: color($sl-colors, icon);
$searchbar-md-input-search-icon-color: color($sl-colors, icon);
$searchbar-wp-input-search-icon-color: color($sl-colors, icon);
$searchbar-ios-input-search-icon-svg: "<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 14'><path fill='fg-color' fill-rule='evenodd' d='M13.743 12.574L9.91 8.74a5.425 5.425 0 0 0 1.05-3.232A5.532 5.532 0 0 0 5.46 0 5.436 5.436 0 0 0 0 5.468a5.532 5.532 0 0 0 5.5 5.508 5.408 5.408 0 0 0 3.242-1.061l.004-.003 3.83 3.831a.826.826 0 1 0 1.167-1.169zM5.496 9.878a4.426 4.426 0 0 1-4.4-4.406 4.35 4.35 0 0 1 4.368-4.374 4.425 4.425 0 0 1 4.4 4.406 4.35 4.35 0 0 1-4.368 4.374z'/></svg>"; // sass-lint:disable-line quotes
$searchbar-ios-input-text-color: color($sl-text, primary);
$searchbar-md-input-text-color: color($sl-text, primary);
$searchbar-wp-input-text-color: color($sl-text, primary);

// Tabs
// --------------------------------------------------
$tabs-background: rgba(255, 255, 255, 0.97);
$tabs-md-tab-opacity: 0.54;
$tabs-tab-color-active: color($sl-colors, primary);
$tabs-tab-color-inactive: color($sl-text, secondary);
$tabs-ios-tab-color-inactive: $tabs-tab-color-inactive;
$tabs-md-tab-color-inactive: $tabs-tab-color-inactive;
$tabs-wp-tab-color-inactive: $tabs-tab-color-inactive;
$tabs-ios-tab-icon-color-active: $tabs-tab-color-active;
$tabs-md-tab-icon-color-active: $tabs-tab-color-active;
$tabs-wp-tab-icon-color-active: $tabs-tab-color-active;
$tabs-ios-tab-icon-color: $tabs-tab-color-inactive;
$tabs-md-tab-icon-color: $tabs-tab-color-inactive;
$tabs-wp-tab-icon-color: $tabs-tab-color-inactive;
$tabs-md-tab-icon-left-transform-x-active: 0;
$tabs-md-tab-icon-transform-y-active: 0;
$tabs-md-tab-text-transform-active: none;

// Segment
// --------------------------------------------------

$segment-button-md-font-size: 1.4rem;
$segment-button-md-padding-end: 4px;
$segment-button-md-text-color-activated: color($sl-colors, primary);

// Button
// --------------------------------------------------

$button-md-border-radius: 4px;
$button-md-box-shadow: none;
$button-md-box-shadow-activated: none;

// Card
// --------------------------------------------------

$card-ios-border-radius: 6px;
$card-md-border-radius: 6px;
$card-wp-border-radius: 6px;

$card-ios-header-font-size: 2.4rem;
$card-md-header-font-size: 2.4rem;
$card-wp-header-font-size: 2.4rem;
$card-ios-header-color: color($sl-text, primary);
$card-md-header-color: color($sl-text, primary);
$card-wp-header-color: color($sl-text, primary);

$card-ios-title-font-size: 2.4rem;
$card-md-title-font-size: 2.4rem;
$card-wp-title-font-size: 2.4rem;
$card-ios-title-color: color($sl-text, primary);
$card-md-title-color: color($sl-text, primary);
$card-wp-title-color: color($sl-text, primary);

$card-ios-text-color: color($sl-text, secondary);
$card-md-text-color: color($sl-text, secondary);
$card-wp-text-color: color($sl-text, secondary);

// Popover
// --------------------------------------------------

$popover-md-width: auto;
$popover-md-min-width: 112px;
$popover-md-min-height: 40px;
$popover-md-text-color: color($sl-text, primary);

// List
// --------------------------------------------------

$list-border-color: color($sl-colors, divider);
$list-ios-border-color: $list-border-color;
$list-md-border-color: $list-border-color;
$list-wp-border-color: $list-border-color;

$list-ios-header-font-size: 1.6rem;
$list-md-header-font-size: 1.6rem;
$list-wp-header-font-size: 1.6rem;
$list-ios-header-font-weight: bold;
$list-md-header-font-weight: bold;
$list-wp-header-font-weight: bold;
$list-ios-header-color: color($sl-text, primary);
$list-md-header-color: color($sl-text, primary);
$list-wp-header-color: color($sl-text, primary);

// Item
// --------------------------------------------------

$item-ios-divider-font-size: 1.6rem;
$item-md-divider-font-size: 1.6rem;
$item-wp-divider-font-size: 1.6rem;
$item-ios-divider-background: transparent;
$item-md-divider-background: transparent;
$item-wp-divider-background: transparent;
$item-ios-divider-color: color($sl-text, primary);
$item-md-divider-color: color($sl-text, primary);
$item-wp-divider-color: color($sl-text, primary);

$item-ios-font-size: 1.4rem;
$item-md-font-size: 1.4rem;
$item-wp-font-size: 1.4rem;
$item-ios-body-text-font-size: 1.4rem;
$item-md-body-text-font-size: 1.4rem;
$item-wp-body-text-font-size: 1.4rem;

// Label
// --------------------------------------------------

$label-ios-text-color: color($sl-text, primary);
$label-md-text-color: color($sl-text, primary);
$label-wp-text-color: color($sl-text, primary);

// Text Input
// --------------------------------------------------

$text-input-ios-highlight-color: color($sl-colors, input-line);
$text-input-md-highlight-color: color($sl-colors, input-line);
$text-input-wp-highlight-color: color($sl-colors, input-line);
$text-input-highlight-color-invalid: color($sl-colors, primary);
$text-input-highlight-color-valid: color($sl-colors, input-line);

// Thumbnail
// --------------------------------------------------

$item-ios-thumbnail-size: 5.6rem;
$item-md-thumbnail-size: 5.6rem;
$item-wp-thumbnail-size: 5.6rem;

// Chip
// --------------------------------------------------

$chip-ios-font-size: 1.4rem;
$chip-md-font-size: 1.4rem;
$chip-wp-font-size: 1.4rem;

// Refresher
// --------------------------------------------------

$refresher-icon-color: color($sl-colors, primary);
$refresher-border-color: transparent;

// Loading
// --------------------------------------------------

$loading-ios-spinner-color: color($sl-colors, primary);
$loading-md-spinner-color: color($sl-colors, primary);
$loading-wp-spinner-color: color($sl-colors, primary);

// Spinner
// --------------------------------------------------

$spinner-ios-bubbles-color: color($sl-colors, primary);
$spinner-ios-circles-color: color($sl-colors, primary);
$spinner-ios-crescent-color: color($sl-colors, primary);
$spinner-ios-dots-color: color($sl-colors, primary);
$spinner-ios-ios-color: color($sl-colors, primary);
$spinner-md-bubbles-color: color($sl-colors, primary);
$spinner-md-circles-color: color($sl-colors, primary);
$spinner-md-crescent-color: color($sl-colors, primary);
$spinner-md-dots-color: color($sl-colors, primary);
$spinner-md-ios-color: color($sl-colors, primary);
$spinner-wp-bubbles-color: color($sl-colors, primary);
$spinner-wp-circles-color: color($sl-colors, primary);
$spinner-wp-crescent-color: color($sl-colors, primary);
$spinner-wp-dots-color: color($sl-colors, primary);
$spinner-wp-ios-color: color($sl-colors, primary);

// Infinite Scroll
// --------------------------------------------------

$infinite-scroll-loading-color: color($sl-colors, primary);

// Toast
// --------------------------------------------------

$toast-ios-background: color($sl-colors, primary);
$toast-md-background: color($sl-colors, primary);
$toast-wp-background: color($sl-colors, primary);
$toast-ios-title-font-size: 1.6rem;
$toast-md-title-font-size: 1.6rem;
$toast-wp-title-font-size: 1.6rem;

// App Theme
// --------------------------------------------------
// Ionic apps can have different themes applied, which can
// then be future customized. This import comes last
// so that the above variables are used and Ionic's
// default are overridden.

@import "ionic.theme.default";

// Ionicons
// --------------------------------------------------
// The premium icon font for Ionic. For more info, please see:
// http://ionicframework.com/docs/v2/ionicons/

$ionicons-font-path: "../assets/fonts";
@import "ionic.ionicons";
