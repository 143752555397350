// [hidden-breakpoint-down]
// Hides the element for the specified breakpoint and smaller

@for $i from 1 to length($grid-breakpoints) {
  [hidden-#{nth(map-keys($grid-breakpoints), $i)}-down] {
    @media (max-width: nth(map-values($grid-breakpoints), $i + 1) - 1px) {
      display: none;
    }
  }
}

// [hidden-breakpoint-up]
// Hides the element for the specified breakpoint and larger

@for $i from 1 through length($grid-breakpoints) {
  [hidden-#{nth(map-keys($grid-breakpoints), $i)}-up] {
    @media (min-width: nth(map-values($grid-breakpoints), $i)) {
      display: none;
    }
  }
}
