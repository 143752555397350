sl-invitation-item {
  display: block;

  .item {
    sl-user-avatar[item-start] {
      margin-right: 16px;
    }
  }

  .invitation {
    font-size: 1.4rem;

    sl-user-avatar {
      align-self: flex-start;
    }

    .message {
      white-space: normal;
      color: color($sl-text, primary);
      font-size: 1.4rem;
    }

    .invitation-status ion-icon {
      font-size: 1.4rem;
    }
  }
}
