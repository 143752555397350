.list-card {
  .thumbnail,
  .thumbnail::after {
    border-radius: 0;
  }

  .thumbnail-col {
    padding: 1px;

    &:nth-child(1) .thumbnail,
    &:nth-child(1) .thumbnail::after {
      border-top-left-radius: 6px;
    }

    &:nth-child(2) .thumbnail,
    &:nth-child(2) .thumbnail::after {
      border-top-right-radius: 6px;
    }

    &:nth-child(3) .thumbnail,
    &:nth-child(3) .thumbnail::after {
      border-bottom-left-radius: 6px;
    }

    &:nth-child(4) .thumbnail,
    &:nth-child(4) .thumbnail::after {
      border-bottom-right-radius: 6px;
    }
  }

  .title {
    line-height: 2.4rem;
  }
}
