profile-page {
  .profile {
    font-size: 1.4rem;

    h2,
    p {
      margin: 0;
    }

    .name {
      font-size: 1.6rem;
      font-weight: bold;
    }

    .verified {
      font-size: 1.6rem;
    }

    > .username {
      color: color($sl-text, secondary);
    }

    .profile-image {
      position: relative;
    }

    .stats-block {
      text-align: center;

      .stats-number {
        display: block;
      }

      .stats-label {
        color: color($sl-text, secondary);
      }
    }

    .stats-inline {
      margin: 8px 0 0;

      > span:hover {
        color: color($sl-text, secondary);
      }

      .stats-label {
        margin-right: 8px;
      }
    }

    .stats-number {
      font-size: 1.6rem;
      font-weight: 600;
    }

    .stats-label {
      text-transform: lowercase;
    }

    .description {
      margin-top: 8px;
    }

    .url {
      margin: 4px 0 0;
      text-transform: initial;
      color: color($sl-colors, primary-600);
    }
  }

  .segment-md .segment-button {
    flex-basis: auto;
    padding: 0;
    width: auto;
    overflow: visible;
    text-overflow: clip;

    .number {
      font-weight: bold;
    }
  }

  ion-segment {
    flex-grow: 0;
  }

  .no-results {
    width: 100%;
    height: 100%;
    text-align: center;
  }

  @media (min-width: map-get($grid-breakpoints, md)) {
    ion-segment {
      padding: 0 16px;
    }
  }

  @media (min-width: map-get($grid-breakpoints, lg)) {
    ion-segment {
      padding: 0 20px;
    }

    .profile {
      font-size: 1.6rem;

      .name,
      .name .verified {
        font-size: 2rem;
      }

      .stats-inline,
      .description {
        margin-top: 16px;
      }

      .url {
        margin-top: 8px;
      }
    }
  }
}
