save-product-page,
save-products-page {
  .grid {
    min-height: 100%;
  }

  .item ion-thumbnail {
    position: relative;
    border-radius: 6px;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    min-width: 3.2rem;
    min-height: 3.2rem;

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: 6px;
      background-color: color($sl-colors, hover);
      content: '';
    }
  }

  .lists {
    margin-bottom: 0;
  }

  .saved {
    margin: auto;
    text-align: center;

    ion-col {
      margin: auto;
    }

    .large-icon {
      line-height: 1;
      font-size: 10rem;
    }

    .check-icon {
      color: color($sl-colors, green);
    }

    h1 {
      font-size: 2rem;
      font-weight: bold;
    }

    p {
      margin-bottom: 0;
      font-size: 1.6rem;
    }
  }
}
