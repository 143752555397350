.base-card {
  display: block;
  transition: transform 100ms linear;
  margin: 0;
  border-radius: 8px;
  box-shadow: $sl-card-shadow;
  padding: 4px;
  width: 100%;
  height: 100%;

  &:hover,
  &:active {
    transform: scale3d(0.98, 0.98, 1);
  }

  .card-container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .username-col {
    flex-shrink: 1;
    padding: 4px 8px 8px 4px;
    overflow: hidden;
  }

  sl-user-avatar {
    @include text-fade;
    padding: 0;
  }

  .more-col {
    padding: 4px 0 8px;
  }

  .more {
    margin: 0;
    width: 2.4rem;
    height: 2.4rem;

    ion-icon {
      padding: 0;
      font-size: 2.4rem;
    }
  }

  .media-col {
    padding: 0;
  }

  .thumbnail {
    position: relative;
    border-radius: 6px;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    padding-bottom: 100%;
    width: 100%;
    height: 0;

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: 6px;
      background-color: color($sl-colors, hover);
      content: '';
    }

    &.media,
    &.media::after {
      border-radius: 6px;
    }
  }

  .actions {
    flex-wrap: nowrap;
    justify-content: center;

    &.public button {
      padding: 0 0.3em;
      font-size: 1.2rem;

      .sl-icon {
        padding-right: 0;
      }
    }

    ion-col {
      padding: 0 5px;
    }

    .save,
    .like {
      flex-basis: auto;
      flex-shrink: 1;
      width: auto;
    }

    button {
      margin: 0;
      height: 3.2rem;
      text-transform: uppercase;
      font-size: 1rem;

      &.purchased {
        color: color($sl-colors, green);
      }
    }
  }

  .info-container {
    flex-grow: 1;
  }

  .title-col {
    padding: 8px 8px 4px 4px;
  }

  .title {
    @include text-fade;
    line-height: 2rem;
    color: color($sl-text, primary);
    font-size: 1.4rem;
    font-weight: 600;
  }

  .subtitle {
    @include text-fade;
    line-height: 2rem;
    color: color($sl-text, secondary);
    font-size: 1.4rem;

    &.sale {
      color: color($sl-colors, primary);
    }

    &.purchased {
      color: color($sl-colors, green);
    }
  }
}
