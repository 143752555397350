sl-variant-selector {
  .options {
    ion-label {
      margin-top: 0;
    }

    ion-select {
      padding-top: 0;
    }
  }

  .option {
    overflow: hidden;

    .option-values {
      height: 0;

      &.expanded {
        height: auto;
      }

      .value {
        display: inline-block;
        max-width: 100%;

        button {
          margin-right: 4px;
          max-width: 100%;
          text-align: left;
          text-transform: initial;

          .button-inner {
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 2em;
          }
        }
      }
    }
  }

  .selected-value {
    ion-icon[name='md-arrow-dropdown'] {
      padding-left: 5px;
      vertical-align: middle;
      color: color($sl-colors, icon);
    }
  }
}
