edit-profile-page {
  .file-input {
    display: none;
  }

  .profile-image {
    position: relative;
    margin-left: 16px;
    width: 80px;
    height: 80px;

    button {
      margin: 0;
      border-radius: 50%;
      background-color: transparent;
      width: 100%;
      height: 100%;
    }

    ion-icon {
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 50%;
      background-color: color($sl-colors, secondary-alt);
      padding: 0;
      width: 100%;
      height: 100%;
      text-align: center;
      line-height: 80px;
    }
  }

  .error {
    color: color($sl-colors, primary);
  }
}
