.edit-notes {

  ion-grid {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  ion-item,
  .item-inner,
  .input-wrapper,
  ion-textarea,
  textarea {
    height: 100%;
  }

  // Remove ion-item edge shadow
  .item.item-input.input-has-focus .item-inner {
    box-shadow: none;
  }

  ion-textarea textarea {
    margin: 0;
    width: 100%;
    text-align: justify;
    color: color($sl-text, secondary);
    font-size: 1.6rem;
  }

}
