sl-logo-navbar {
  display: block;

  .bar-buttons[left],
  .logo {
    display: flex;
    align-items: center;
  }

  .back {
    margin: 0 16px 0 0;
    height: 3.2rem;
  }
}
