list-page {
  .list-grid {
    position: relative;
    padding: 0;
    min-height: 100%;

    > ion-row {
      padding: 5px;
    }

    > .media-container {
      display: flex;
      position: relative;
      align-items: center;
      margin-bottom: 8px;
      overflow: hidden;

      &.has-media {
        .media {
          min-height: 360px;

          &::after {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: linear-gradient(to bottom, color($sl-colors, hint), color($sl-colors, secondary-alt));
            content: '';
          }
        }

        .list-info {
          position: relative;
          bottom: 0;
          left: 0;
          z-index: 1;

          .username,
          .title,
          .description {
            color: #fff;
          }

          .add-user {
            border-color: rgba(255, 255, 255, 0.5);
            color: rgba(255, 255, 255, 0.5);
          }

          .url {
            button {
              color: rgba(255, 255, 255, 0.7);
            }
          }
        }
      }
    }
  }

  .media {
    display: flex;
    position: relative;
    align-items: flex-end;
    background-position: 50% 50%;
    background-size: cover;
    width: 100%;
  }

  .list-info {
    position: relative;
    padding: 16px;
    width: 100%;
    color: color($sl-text, primary);

    .collaborators {
      margin-bottom: 16px;

      .owner {
        padding-left: 0;

        sl-user-avatar {
          font-size: 1.6rem;

          .avatar {
            width: 3.2rem;
            height: 3.2rem;
          }
        }
      }

      .username {
        padding-left: 10px;
        font-weight: 600;
      }

      .collaborator {
        padding-right: 0;
      }

      .add-user {
        display: block;
        border-radius: 50%;
        width: 2.4rem;
        height: 2.4rem;

        ion-icon {
          padding: 0 0.25em;
          line-height: 2.4rem;
        }
      }
    }

    .title {
      margin: 0;
      color: inherit;
      font-weight: bold;
    }

    .description {
      margin: 8px 0 0;
      color: inherit;
      font-size: 1.6rem;
    }

    .url {
      margin: 8px 0 0;

      button {
        margin: 0;
        padding: 0;
        height: auto;
        text-align: initial;
        text-transform: initial;
        text-decoration: underline;
        color: color($sl-text, icon);
        font-size: 1.6rem;

        .button-inner {
          white-space: normal;
        }
      }
    }
  }

  .no-results {
    flex: 1;
    align-content: center;
    width: 100%;
    height: 100%;
    text-align: center;

    p {
      font-size: 1.6rem;
    }
  }

  // Mobile

  @media (max-width: map-get($grid-breakpoints, sm)) {
    .logo {
      display: none;
    }
  }

  // Desktop

  @media (min-width: map-get($grid-breakpoints, lg)) {
    .media-container {
      border-radius: 6px;

      &.has-media {
        margin-top: 16px;
      }
    }
  }
}
