product-page,
item-page {
  ion-row {
    position: relative;
  }

  .card {
    margin: 0 0 16px;
    border-radius: 0;
    box-shadow: none;
    background-color: #fff;
    width: 100%;
  }

  ion-slides {
    position: relative;
    padding-bottom: 100%;
    height: 0;
  }

  .swiper-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: auto;
  }

  .slide-zoom {
    height: 100%;

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      background-color: color($sl-colors, hover);
      content: '';
    }
  }

  .swiper-slide img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .info {
    .title {
      margin-top: 0;
      padding-bottom: 0;
      text-align: center;
      color: color($sl-text, primary);
      font-size: 2rem;
      font-weight: bold;
    }

    .pricing {
      text-align: center;

      .now {
        font-weight: 600;
      }

      .purchased {
        color: color($sl-colors, green);
      }

      .save {
        color: color($sl-colors, primary);
      }

      .original,
      .saved {
        color: color($sl-text, secondary);
      }
    }

    .primary-actions {
      button {
        height: 4.4rem;
      }
    }

    .secondary-actions {
      flex-wrap: wrap;
      padding: 8px 8px 16px;
      text-align: center;

      ion-col {
        flex-shrink: 1;
        padding: 0;
      }

      button {
        margin: 0;

        &.purchased {
          color: color($sl-colors, green);
        }
      }
    }
  }

  .item-divider {
    border-bottom: 0;
  }

  .sold-by,
  .availability,
  .notes,
  .description {
    ion-item ion-label {
      position: relative;
      margin-top: 0;
      max-height: 6.3rem;
      text-align: justify;
      line-height: 2.1rem;
      white-space: normal;
      font-size: 1.4rem;

      &::after {
        position: absolute;
        top: 2.1rem;
        left: 0;
        background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
        width: 100%;
        height: 4.2rem;
        content: '';
      }
    }

    ion-item.expand ion-label {
      max-height: none;

      &::after {
        display: none;
      }
    }
  }

  .sold-by {
    .favicon {
      display: inline-block;
      margin-right: 8px;
      width: 16px;
      height: 16px;
      vertical-align: middle;
    }
  }

  .comments {
    ion-item ion-label {
      line-height: 2.1rem;
      white-space: normal;
      font-size: 1.4rem;
    }

    .comment {
      .avatar[item-start] {
        align-self: flex-start;
        margin: 13px 16px 8px 0;
        width: 40px;
        height: 40px;
      }

      .username {
        color: color($sl-text, primary);
        font-size: 1.4rem;
        font-weight: 600;
      }

      .verified {
        font-size: 1.4rem;
      }

      .message {
        color: color($sl-text, primary);
      }

      .more {
        margin-top: 0;
        margin-right: 0;
      }
    }

    .post {
      ion-textarea {
        font-size: 1.4rem;
      }

      button {
        margin-top: auto;
        margin-bottom: auto;
        max-height: 3.6rem;
      }
    }
  }

  .hint {
    color: color($sl-text, hint);
  }

  @media (max-width: map-get($grid-breakpoints, sm)) {
    .slider .card {
      margin-bottom: 8px;
    }

    .comments {
      margin-bottom: 0;
    }
  }

  @media (max-width: map-get($grid-breakpoints, md)) {
    .product-grid,
    .product-grid > .row > .col {
      padding: 0;
    }
  }

  @media (min-width: map-get($grid-breakpoints, md)) {
    .right {
      position: absolute;
      right: 0;
    }

    .card,
    .swiper-slide img,
    .slide-zoom::after {
      border-radius: 6px;
    }
  }

  @media (min-width: map-get($grid-breakpoints, lg)) {
    .product-grid {
      padding: 16px 0;
    }
  }
}
