sl-info-card {
  display: block;

  .info-card-container {
    margin: 0;
    height: 100%;
  }

  .card {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: space-between;
    transition: transform 100ms linear;
    margin: 0;
    box-shadow: $sl-card-shadow;
    padding: 10px;
    width: 100%;
    height: 100%;

    &:hover,
    &:active {
      transform: scale3d(0.98, 0.98, 1);
    }

    ion-card-title {
      margin: 0 32px 0 0;
      padding: 0;
      color: color($sl-text, primary);
      font-size: 1.6rem;
      font-weight: bold;
    }

    .close-button {
      position: absolute;
      top: 4px;
      right: 4px;
    }

    ion-card-content {
      margin-bottom: auto;
    }

    p {
      margin: 8px 0 0;
      font-size: 1.4rem;
    }

    button {
      margin: 0;
    }

  }

}
