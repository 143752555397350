lists-page {
  .buttons-end.bar-buttons-ios {
    @media (max-width: map-get($grid-breakpoints, sm)) {
      margin-left: 0;
    }
  }

  p.app-icon {
    margin-top: 12px;
    margin-right: 16px;
    padding: 0;

    img {
      width: 3.2rem;
      height: 3.2rem;
    }
  }
}
