extension-page {
  ion-grid {
    justify-content: center;
    min-height: 100%;
  }

  ion-col {
    width: 100%;
    text-align: center;
  }

  h2 {
    font-size: 2.4rem;
    font-weight: bold;
  }

  p {
    font-size: 1.6rem;
  }

  .large-icon {
    line-height: 1;
    font-size: 20rem;
  }

  .check-icon {
    color: color($sl-colors, green);
  }

  button img {
    margin-right: 8px;
  }

}
