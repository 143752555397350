search-page {
  .back {
    margin: 0;
    height: 44px;
  }

  ion-content .toolbar-background {
    background: transparent;
  }

  .segment-md .segment-button {
    flex-basis: auto;
    width: auto;
  }

  .main-grid {
    min-height: 100%;
  }

  .search-grid {
    flex-grow: 1;
    justify-content: center;
  }

  .no-results {
    width: 100%;
    height: 100%;
    text-align: center;
  }

  @media (min-width: map-get($grid-breakpoints, md)) {
    ion-segment {
      padding: 0 12px;
    }
  }

  @media (min-width: map-get($grid-breakpoints, lg)) {
    ion-segment {
      padding: 0 16px;
    }
  }
}
