signup-page,
login-page {
  .header-md::after {
    background-image: none;
  }

  .grid[fixed] {
    position: relative;
    align-items: center;
    justify-content: center;
    min-height: 100%;
  }

  .bar-buttons-md {
    margin: 0 8px;
  }

  .form-container {
    margin: 0 auto;
    width: 100%;
    max-width: 320px;
  }

  .logo-img {
    display: none;
    margin: 0 auto 24px;
    padding: 0;

    @media (min-height: 768px) {
      display: block;
    }
  }

  h1 {
    margin-top: 0;
    font-size: 2rem;
  }

  .send {
    margin: 24px 0 16px;
  }

  .switch {
    margin: 8px 0;
    text-align: center;
    color: color($sl-text, secondary);
    font-weight: 600;
  }

  .forgot {
    color: color($sl-colors, primary);
    font-weight: 600;
  }

  .footer {
    display: flex;
    flex: 1 0 auto;
    align-items: center;
    justify-content: center;
    text-align: center;

    a {
      padding: 8px 12px;
      text-decoration: none;
      color: color($sl-text, secondary);
    }
  }
}
